let menu = document.getElementById('nav__items');
let open = document.getElementById('nav__open-menu');
let close = document.getElementById('nav__close-menu');

function menuEvent() {
    menu.classList.toggle('nav__items--invisible');
    open.classList.toggle('nav__menu-button--invisible');
    close.classList.toggle('nav__menu-button--invisible');
}

open.addEventListener('click', menuEvent);
close.addEventListener('click', menuEvent);