const acceptCookiesButton = document.getElementById("accept-cookies");
const cookieConsent = document.querySelector(".cookie-consent");

const hasAcceptedCookies = localStorage.getItem("acceptedCookies");
// console.log(hasAcceptedCookies);

if (hasAcceptedCookies != null) {
   
    cookieConsent.style.display = "none";
} else {
    acceptCookiesButton.addEventListener("click", () => {

        localStorage.setItem("acceptedCookies", "true");

        cookieConsent.style.display = "none";
    });
}
