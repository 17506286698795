let activeFormButton = document.getElementById("active-form__box");
let activeFormWindow = document.getElementById("active-form-window");
let activeFormClose = document.getElementById("active-form-window__close");

if (activeFormButton !== null && activeFormWindow !== null) {
    activeFormButton.addEventListener("click", () => {
        activeFormWindow.style.display = "block";
    });
}

if (activeFormClose !== null && activeFormWindow !== null) {
    activeFormClose.addEventListener("click", () => {
        activeFormWindow.style.display = "none";
    });
}
